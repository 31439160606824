.theme-light {
  --background-color: #1a1a1a;
  --text-color: #fff;
  --background-button: #1976d2;
  --background-input: #e1f0ff;
  --text-input: #1a1a1a;
}
.theme-dark {
  --background-color: #fff;
  --text-color: #1a1a1a;
  --background-input: #e1f0ff;
  --background-button: #1976d2;
  --text-input: #1a1a1a;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.theme-dark{
  // background-color: #000;
}
.layout__container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}
@media only screen and (max-width: 450px) {
  .layout__container {
    max-width: 375px;
    margin: 0 auto;
    padding: 7vw;
    padding-top: 3vw;
  }
}
.layout__container {
  .header__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 100px;
    }
  }
  .login__page {
    h2 {
      color: #1a1a1a;
      font-size: 32px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
    }
    .subtitle {
      color: #1a1a1a;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
    }
    .text__box__login {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-top: 30px;
      margin-bottom: 35px;
    }
    .form__login {
      display: flex;
      flex-direction: column;
      gap: 20px;
      input {
        border-radius: 10px;
        border: none !important;
      }
      input:hover {
        border: none !important;
      }
    }
    a {
      text-align: center;
      width: 100%;
      display: block;
    }
  }
  img {
    width: 100%;
  }
  a {
    text-decoration: none;
  }
  a:hover {
    color: var(--text-color);
  }
}
.help.is-danger {
  color: 1a1a1a;
}
.header__box__logo {
  position: relative;
}
.header__box__logo img {
  width: 52px;
}
.header__auth {
  .link,
  a {
    color: var(--background-color);
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
    border: none;
    background-color: transparent;
  }
  .link {
    width: 100%;
    text-align: left;
  }
  button.icon {
    color: var(--background-color) !important;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
    background: var(--text-color);
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  a:hover {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  .link:hover {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  .menu {
    position: fixed;
    height: 100vh;
    background-color: var(--text-color);
    top: 0;
    left: 0;
    width: 0%;
    transition: width 1s;
  }
  .menu.active {
    width: 70%;
  }
  @media only screen and (min-width: 450px) {
    .menu.active {
      width: 15%;
    }
    .menu {
      position: fixed;
      height: 100vh;
      background-color: var(--text-color);
      top: 0;
      left: 38%;
      width: 0%;
      transition: width 1s;
    }
  }
  .menu a {
    display: none;
    transition: display 1s;
  }
  .menu.active .link {
    display: block;
  }
  .menu .link {
    display: none;
    transition: display 1s;
  }
  .menu.active a {
    display: block;
  }
}
body {
  font-family: "Roboto", sans-serif !important;
}

body .avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

body .avatar img {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 50%;
  box-shadow: 0px 5px 8px -3px var(--background-color);
}

.contact {
  padding-top: 38px;
  width: 100%;
}

.contact .contact__name {
  color: var(--background-color);

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}

.contact .contact__job_position {
  color: var(--background-color);

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 8px;
  margin-bottom: 10px;
  text-transform: capitalize;
  text-align: center;
  display: block;
}

.contact .contact__company {
  color: var(--background-color);

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
}

.contact__bottom {
  margin-top: 48px;
}

.contact__bottom__box {
  background: var(--text-color);
  display: flex;
  align-items: center;
  padding-top: 11.5px;
  padding-bottom: 14.5px;
  border-bottom: 1px solid var(--text-color);
  gap: 16px;
  border-radius: 50px;
  padding: 10px 25px;
  margin: 10px 0;
}
.contact__bottom__box:hover {
  cursor: pointer;
}
.contact__bottom__box--text {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.contact__bottom__box--title {
  color: var(--background-color);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contact__bottom__box--content {
  color: var(--background-color);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#save-btn {
  width: 62px;
  height: 62px;
  background-color: transparent;
  border: none;
  position: fixed;
  bottom: 41px;
  right: calc(50% - 200px);
}

body .layout__container .background {
  position: absolute;
  max-width: 450px;
  margin: 0 auto;
  padding: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
  background-image: url(https://hcsoftvn.com/wp-content/uploads/2023/09/img.svg);
  background-size: cover;
}

body .layout__container .background__img {
  position: absolute;
  max-width: 450px;
  margin: 0 auto;
  padding: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: var(--text-color);
  opacity: 0.7;
}
body .layout__container .background__img.demo {
  opacity: 0.5;
}
@media only screen and (max-width: 450px) {
  body .layout__container .background {
    padding: 7vw;
    padding-top: 3vw;
  }

  body .layout__container .background__img {
    padding: 7vw;
    padding-top: 3vw;
  }
}
body .logo__avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;
}

body .logo__avatar img {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 50%;
  object-fit: cover;
}

#root {
  height: 100vh;
  overflow: scroll;
}
svg {
  color: var(--background-color) !important;
}
.contact__bottom__box:last-child {
  border: none;
}
.layout__container .contact__bottom__box.last {
  border-bottom: 0 !important;
}

#save-btn {
  width: 62px;
  height: 62px;
  background-color: transparent;
  border: none;
  position: fixed;
  bottom: 41px;
  right: calc(50% - 200px);
  border: 2px solid var(--background-color);
  border-radius: 50%;
  background: var(--text-color);
  font-size: 25px;
}
@media only screen and (max-width: 450px) {
  #save-btn {
    width: 62px;
    height: 62px;
    background-color: transparent;
    border: none;
    position: fixed;
    bottom: 15px;
    right: 15px;
    border: 2px solid var(--background-color);
    border-radius: 50%;
    font-size: 25px;
    background: var(--text-color);
  }
}

.list__info {
  display: flex;
  flex-direction: column;
  & > .position {
    order: 3;
  }
  & > .cty {
    order: 4;
  }
  & > .phone {
    order: 5;
  }
  & > .dv {
    order: 6;
  }
  & > .cd {
    order: 7;
  }
  & > .dia_chi {
    order: 8;
  }
  & > .faceboook {
    order: 9;
  }
  & > .email {
    order: 10;
  }
  & > .tiktok {
    order: 11;
  }
  & > .taikhoan_nganhang {
    order: 12;
  }
  & > .web {
    order: 13;
  }
  & > .website {
    order: 13;
  }
}
.login__cup {
  cursor: pointer;
}
.layout__container .login__page a:hover {
  color: #1a1a1a;
}

.header__back {
  color: var(--background-color);
}
.title {
  color: var(--background-color);
}
.subtitle {
  color: var(--background-color);
}
.add__info {
  color: var(--background-color) !important;
}
.mode_css label {
  color: #fff !important;
}
input,
.MuiSelect-select {
  background: var(--background-input) !important;
  color: var(--text-input) !important;
}
.mode_css {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mode_css button {
  width: 300px;
}
.header__back {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 5px;
}
.add__info {
  margin: 10px 0;
}

.title {
  margin: 10px 0;
}

.subtitle {
  margin: 5px 0;
  text-transform: capitalize;
}

body .logo__avatar {
  margin-bottom: 40px;
}
.mode_css {
  max-width: 480px;
  margin: 0 auto;
  padding-top: 1vw;
  img {
    width: 100%;
  }
}
@media only screen and (max-width: 450px) {
  .mode_css {
    max-width: 480px;
    margin: 0 auto;
    padding-top: 3vw;
  }
  img {
    width: 100%;
  }
}

.MuiMenu-list {
  text-transform: capitalize;
}

.input__group {
  margin-top: 2em;
}
.input__group button {
  border: unset;
  background: hsl (231, 77%, 90%);
  padding: 1em 0.75em;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}

#app {
  height: 100vh;
  overflow: scroll;
}
.change__img {
  object-fit: cover;
  max-height: 68vh;
  margin: 0 auto;
  align-items: center;
  text-align: center;
}
label[for="upload"] {
  display: flex;
  flex-direction: column;
}
